import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Claripi } from "../res/Logo_Claripi.svg";
import ClariDenoise from "../res/Product_ClariDenoising.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function ClariDenoisePage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("clari-denoise-t1")}
            {/* 저선량 CT 영상 검출 디노이징 솔루션 */}
          </p>
          <div className="Product-logo">
            <Claripi width={74} height={18} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("clari-denoise-t2")}
              {/* 의료영상전송장치소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={ClariDenoise} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            {t("clari-denoise-t3")}
            {/* AI 기반의 */}
            <span className="Text-point">
              {t("clari-denoise-t4")}
              {/* 선명한 이미지 */}
            </span>
            {t("clari-denoise-t5")}
            {/* 획득 */}
          </p>
          <p
            className="Product-text"
            style={{ marginTop: "4vh" }}
            data-aos="fade-up"
          >
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("clari-denoise-t6")}
              {/* 이미지 품질과 방사선 선량 사이의 */}
            </span>
            <br className="hidden min-hidden576" />
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("clari-denoise-t7")}
              {/* 균형 최적화 */}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
