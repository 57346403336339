import React from "react";

import KR1 from "../res/brochure/kr/Brochure.jpg";
import KR2 from "../res/brochure/kr/Brochure2.jpg";
import KR3 from "../res/brochure/kr/Brochure3.jpg";
import KR4 from "../res/brochure/kr/Brochure4.jpg";
import KR5 from "../res/brochure/kr/Brochure5.jpg";
import KR6 from "../res/brochure/kr/Brochure6.jpg";
import KR7 from "../res/brochure/kr/Brochure7.jpg";
import KR8 from "../res/brochure/kr/Brochure8.jpg";
import KR9 from "../res/brochure/kr/Brochure9.jpg";
import KR10 from "../res/brochure/kr/Brochure10.jpg";
import KR11 from "../res/brochure/kr/Brochure11.jpg";
import KR12 from "../res/brochure/kr/Brochure12.jpg";
import KR13 from "../res/brochure/kr/Brochure13.jpg";
import { Carousel } from "react-responsive-carousel";

export default function Brochure() {
  const kr = [
    { image: KR1 },
    { image: KR2 },
    { image: KR3 },
    { image: KR4 },
    { image: KR5 },
    { image: KR6 },
    { image: KR7 },
    { image: KR8 },
    { image: KR9 },
    { image: KR10 },
    { image: KR11 },
    { image: KR12 },
    { image: KR13 },
  ];

  return (
    <div className="brochure-container">
      <Carousel showThumbs={false} autoPlay={true}>
        {kr.map((kr) => (
          <div>
            <img src={kr.image} class="img" alt="e-brochure" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
