import React from "react";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as LunitCXRLogo } from "../res/Lunit_CXR.svg";
import LCXR from "../res/Product_LunitCXR.png";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";

const FindingItem = (props) => {
  return <div className={props.style}>{props.name}</div>;
};

export default function LunitCXRPage() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            CHEST X-RAY
            <br />
            {t("lunit-cxr-t1")}
            {/* 영상 검출 및 진단 보조 솔루션 */}
          </p>
          <div className="Product-logo">
            <LunitCXRLogo width={185} height={20} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("lunit-cxr-t2")}
              {/* 의료영상검출·진단보조소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={LCXR} className="Product-image" alt="LunitCXR" />
        </div>

        <p className="Product-text-Inno" data-aos="fade-up">
          {t("lunit-cxr-t3")}
          {/* ※ 혁신의료기술로 지정되어 비급여 적용 가능합니다. */}
        </p>
        <p
          data-aos="fade-up"
          className="Product-text"
          style={{
            fontSize: "2.6rem",
            marginBottom: "5vh",
            fontWeight: "800",
            maxWidth: "none",
          }}
        >
          <span
            className={i18n.language === "ko" ? "Text-point" : "Text-normal"}
          >
            {t("lunit-cxr-t4")}
            {/* 10가지 */}
          </span>
          <br className="min-show768" />
          <span
            className={i18n.language === "ko" ? "Text-normal" : "Text-point"}
          >
            {t("lunit-cxr-t5")}
            {/* 비정상소견 검출 */}
          </span>
        </p>
        <div className="Finding-container" data-aos="fade-up">
          <div className="Finding-group">
            <FindingItem name={t("lunit-cxr-t6")} style="Finding" />
            {/* 결절 */}
            <FindingItem name={t("lunit-cxr-t7")} style="Finding" />
            {/* 기흉 */}
            <FindingItem name={t("lunit-cxr-t8")} style="Finding" />
            {/* 경화 */}
            <FindingItem name={t("lunit-cxr-t9")} style="Finding" />
            {/* 흉수 */}
            <FindingItem name={t("lunit-cxr-t10")} style="Finding" />
            {/* 기복증 */}
            <FindingItem name={t("lunit-cxr-t11")} style="Finding" />
            {/* 무기폐 */}
            <FindingItem name={t("lunit-cxr-t12")} style="Finding" />
            {/* 석회 */}
            <FindingItem name={t("lunit-cxr-t13")} style="Finding" />
            {/* 폐섬유화 */}
            <FindingItem name={t("lunit-cxr-t14")} style="Finding" />
            {/* 심장 비대 */}
            <FindingItem name={t("lunit-cxr-t15")} style="Finding" />
            {/* 종격동 비대 */}
            <FindingItem name={t("lunit-cxr-t16")} style="Finding100" />
            {/* 폐결핵 선별 지원 */}
          </div>
        </div>
      </div>
    </div>
  );
}
