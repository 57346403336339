import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Deepnoide } from "../res/Logo_Deepnoide.svg";
import DeepCT from "../res/Product_DeepCT.png";
import DeepSolid from "../res/Deep_solid.png";
import DeepPartSolid from "../res/Deep_partsolid.png";
import DeepGGO from "../res/Deep_ggo.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function DeepCTPage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("deep-ct-t1")}
            {/* 저선량 흉부 CT 영상 검출 및 */}
            <br />
            {t("deep-ct-t2")}
            {/* 진단 보조 솔루션 */}
          </p>
          <div className="Product-logo">
            <Deepnoide width={130} height={20} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("deep-ct-t3")}
              {/* 의료영상검출·보조소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={DeepCT} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("deep-ct-t4")}
              {/* 폐 결절 */}
            </span>
            <br
              className={i18n.language === "ko" ? "hidden " : "min-hidden576"}
            />
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("deep-ct-t5")}
              {/* 의심 부위 검출 보조 */}
            </span>
          </p>
        </div>

        <div className="Product-desc-container" style={{ columnGap: "20px" }}>
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={DeepSolid}
              className="Product-image"
              style={{ maxWidth: "210px", marginBottom: "20px" }}
            />
            <p
              className="Text-c"
              style={{ fontSize: "1.2rem", fontWeight: "800" }}
            >
              Solid
            </p>
          </div>
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={DeepPartSolid}
              className="Product-image"
              style={{ maxWidth: "210px", marginBottom: "20px" }}
            />
            <p
              className="Text-c"
              style={{ fontSize: "1.2rem", fontWeight: "800" }}
            >
              Part Solid
            </p>
          </div>
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={DeepGGO}
              className="Product-image"
              style={{ maxWidth: "210px", marginBottom: "20px" }}
            />
            <p
              className="Text-c"
              style={{ fontSize: "1.2rem", fontWeight: "800" }}
            >
              GGO
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
