import React from "react";
import { Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as Lunit } from "../res/Logo_Lunit.svg";
import { ReactComponent as AIinsight } from "../res/Logo_AIinsight.svg";
import { ReactComponent as Neurophet } from "../res/Logo_Neurophet.svg";
import { ReactComponent as Promedius } from "../res/Logo_Promedius.svg";
import { ReactComponent as Deepnoide } from "../res/Logo_Deepnoide.svg";
import { ReactComponent as Mediwhale } from "../res/Logo_Mediwhale.svg";
import { ReactComponent as Claripi } from "../res/Logo_Claripi.svg";
import i18n from "../language/i18n";

const items = [
  {
    key: "1",
    label: (
      <Link to="/LunitCXR">
        Chest X-ray
        <Lunit height={16} width={60} />
      </Link>
    ),
  },
  {
    key: "2",
    label: (
      <Link to="/LunitMMG">
        Mammography
        <Lunit height={16} width={60} />
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link to="/AIFAI">
        Fundus
        <AIinsight height={22} width={98} />
      </Link>
    ),
  },
  {
    key: "4",
    label: (
      <Link to="/MCVD">
        Fundus/CVD
        <Mediwhale height={11.5} width={119} />
      </Link>
    ),
  },
  {
    key: "5",
    label: (
      <Link to="/DeepMRA">
        Brain MRA
        <Deepnoide height={15.5} width={103} />
      </Link>
    ),
  },
  {
    key: "6",
    label: (
      <Link to="/DeepCT">
        Lung CT
        <Deepnoide height={15.5} width={103} />
      </Link>
    ),
  },
  {
    key: "7",
    label: (
      <Link to="/DeepSpine">
        Spine X-ray
        <Deepnoide height={15.5} width={103} />
      </Link>
    ),
  },
  {
    key: "8",
    label: (
      <Link to="/ClariLow">
        Low-Dose CT
        <Claripi height={13.5} width={58} />
      </Link>
    ),
  },
  {
    key: "9",
    label: (
      <Link to="/ClariDenoise">
        CT Image Denoising
        <Claripi height={13.5} width={58} />
      </Link>
    ),
  },
  {
    key: "10",
    label: (
      <Link to="/NeuroMRI">
        MRI Quantification
        <Neurophet height={24} width={97} style={{ marginTop: "-4px" }} />
      </Link>
    ),
  },
  {
    key: "11",
    label: (
      <Link to="/PromediusCT">
        Abdominal CT
        <Promedius height={24} width={106} />
      </Link>
    ),
  },
  {
    key: "12",
    label: (
      <Link
          to={i18n.language === "ko" ? "/Brochure" : "/BrochureEN"}
        >
          e-Brochure (All Solutions)
        </Link>
    ),
  },
];

export default function HeaderProductMenu() {
  return (
    <div style={{ zIndex: 20 }}>
      <Dropdown menu={{ items }} placement="bottomLeft">
        <Button>Products</Button>
      </Dropdown>
    </div>
  );
}
