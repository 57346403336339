import React from "react";
import { Tabs } from "antd";
import "../App.css";
import NewsItem from "./NewsItem";
import Event05 from "../res/newsImage/event5.jpg";
import Event04 from "../res/newsImage/event4.jpg";
import Event03 from "../res/newsImage/event3.jpg";
import Notice0 from "../res/newsImage/notice.png";
import Notice01 from "../res/newsImage/notice1.png";
import Notice02 from "../res/newsImage/notice2.png";
import Notice03 from "../res/newsImage/notice3.png";
import Notice04 from "../res/newsImage/notice4.png";
import Notice05 from "../res/newsImage/notice5.png";
import Notice07 from "../res/newsImage/notice7.png";
import NewsEventPage from "../news/NewsEventPage";
import Event06 from "../res/newsImage/event6.jpg";
import { Routes, Route, Link } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import I18next from "i18next";

export default function NewsThumbnail(value) {
  const { t } = useTranslation();

  const events = [
    {
      image: Event06,
      // title: "말레이시아 NCI(National Cancer Institue)와 협업 준비",
      // desc: "마이허브는 최근 말레이시아 국립 암 센터(NCI)에 중요한 발표의 장을 열었습니다. 이번 프리젠테이션에서는 마이허브의 주요 솔루션인 마이링크를 말레이시아 국립 암 센터의 의료진을 대상으로 소개했습니다.",
      title: I18next.t("mainNew-event-title-t4"),
      desc: I18next.t("mainNew-event-desc-t4"),
      date: "2024, 07",
      link: "https://www.linkedin.com/posts/maihub_introducing-maihub-to-malaysias-national-activity-7223973096607641603-VC_u?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: Event05,
      // title: "마이허브 2024년 3월 개최된 'KIMES 2024'에 참가",
      // desc: "고객 맞춤형 메디컬 AI 플랫폼 ‘마이링크’를 선보였습니다. “AI 보급 확대로 의료환경 선진화에 기여!”",
      title: I18next.t("mainNew-event-title-t3"),
      desc: I18next.t("mainNew-event-desc-t3"),
      date: "2024, 03",
      link: "https://kr.aving.net/news/articleView.html?idxno=1788079",
    },
    {
      image: Event04,
      // title: "제 42회 대한위대장내시경학회 추계학술대에 참가",
      // desc: "2023년 9월에 개최된 제 42회 대한위대장내시경학회 추계학술대에 참가하여 마이링크와 포함된 AI 솔루션들을 소개했습니다.",
      title: I18next.t("mainNew-event-title-t2"),
      desc: I18next.t("mainNew-event-desc-t2"),
      date: "2023, 09",
    },
    // {
    //   image: Event03,
    //   // title:
    //   //   "마이허브 'KIMES 2023'에 참가하여 AI 의료 통합 서비스'마이링크'를 선보임",
    //   // desc: " 2023년 3월 개최된 ‘KIMES 2023’에 참가하여 AI 의료 통합 서비스 ‘maiLink’를 선보였습니다. 다양한 의료영상 AI 솔루션 통합적, 선택적 제공! ",
    //   title: I18next.t("mainNew-event-title-t1"),
    //   desc: I18next.t("mainNew-event-desc-t1"),
    //   date: "2023, 03",
    //   link: "https://kr.aving.net/news/articleView.html?idxno=1777547",
    // },
  ];

  const notices = [
    {
      image: Notice05,
      // title: "마이허브 중기부 TIPS 선정",
      // desc: "'maiLink'가 말레이시아 의료기기청으로부터 MDA인증을 획득했습니다. 이번 인증은 maiLink'가 의료기기로서의 안전성과 효과성을 공식적으로 인정받았음을 의미합니다.",
      title: I18next.t("mainNew-notice-title-t5"),
      desc: I18next.t("mainNew-notice-desc-t5"),
      date: "2024, 06",
      link: "https://www.linkedin.com/posts/maihub_maihub-mailink-ai-activity-7209098526629216257-o9AP?utm_source=share&utm_medium=member_desktop",
    },

    {
      image: Notice07,
      // title: "마이허브 왕진연구소와 MOU 체결",
      // desc: "마이허브는 최근 왕진연구소와 국내 의료분야 사업 협력을 위한 MOU를 체결했습니다. 이번 협약을 통해 양 기관은 방문진료 사업 추진, 의료분야 신규 사업 발굴, 그리고 의료 인공 지능 솔루션 개발 등 다양한 분야에서 협력할 예정입니다.",
      title: I18next.t("mainNew-notice-title-t4"),
      desc: I18next.t("mainNew-notice-desc-t4"),
      date: "2024, 06",
    },

    {
      image: Notice04,
      // title: "'maiLink' MDA 인증 획득",
      // desc: "마이허브의 'maiLink'가 말레이시아 의료기기청으로부터 MDA인증을 획득했습니다. 이번 인증은 maiLink'가 말레이시아에서 의료기기로서의 안전성과 효과성을 공식적으로 인정받았음을 의미합니다. 이번 성과를 바탕으로 마이허브는 말레이시아를 포함한 글로벌 시장에서의 사업 확장과 의료 AI 솔루션 통합 플랫폼 제공에 더욱 박차를 가할 계획입니다.",
      title: I18next.t("mainNew-notice-title-t3"),
      desc: I18next.t("mainNew-notice-desc-t3"),
      date: "2024, 03",
      link: "http://www.bokuennews.com/news/article.html?no=241708",
    },
    // {
    //   image: Notice03,
    //   // title: "이지스헬스케어와 MOU 체결",
    //   // desc: "마이허브는 '이지스전자차트' 활용을 위한 전략적 파트너십을 체결하고, 고객이 의료 AI 솔루션을 보다 편리하게 활용할 수 있도록 지원할 예정입니다. 의료현장에서의 AI 서비스의 접근성과 효율성을 높이기 위해, EMR에서도 AI 솔루션을 사용할 수 있도록 서비스를 확장해나갈 계획입니다.",
    //   title: I18next.t("mainNew-notice-title-t2"),
    //   desc: I18next.t("mainNew-notice-desc-t2"),
    //   date: "2024, 02",
    //   link: "http://www.hitnews.co.kr/news/articleView.html?idxno=52175",
    // },
    // {
    //   image: Notice0,
    //   // title: "마이허브 초기 투자 유치",
    //   // desc: " 마이허브는 의료 AI 전문 투자 회사인 매쉬업엔젤스(MashupAngels)로 부터 초기 투자금을 유치하였습니다. 이번 투자 유치를 통해 AI 서비스 촉진과 팁스(TIPS) 프로그램을 통한 향후 지원을 위한 입지를 다집니다.",
    //   title: I18next.t("mainNew-notice-title-t1"),
    //   desc: I18next.t("mainNew-notice-desc-t1"),
    //   date: "2023, 11",
    //   link: "http://www.hitnews.co.kr/news/articleView.html?idxno=50342",
    // },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container-100">
      <div className="Basic-container">
        <div className="Title-container">
          <p className="Title" data-aos="fade-up">
            {t("mainNew-t1")}
            {/* 최신 주요 소식 */}
          </p>
          <div className="Seperator" data-aos="fade-up" />
          <div className="Tab-container" data-aos="fade-up">
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: "Event",
                  key: "1",
                  children: <EventList />,
                },
                {
                  label: "Notice",
                  key: "2",
                  children: <NoticeList />,
                },
              ]}
            />
          </div>
          <div className="News-more-container" data-aos="fade-up">
            <Link to="/news" className="LineButton">
              {t("mainNew-t2")}
              {/* 다양한 소식 보러가기 */}
            </Link>
          </div>
          <Routes>
            <Route path="/news" element={<NewsEventPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );

  function EventList() {
    return (
      <div className="Thumb-container">
        {events.map((event) => (
          <NewsItem
            NewsImage={event.image}
            NewsTitle={event.title}
            NewsDescription={event.desc}
            NewsDate={event.date}
            link={event.link}
          />
        ))}
      </div>
    );
  }

  function NoticeList() {
    return (
      <div className="Thumb-container">
        {notices.map((notice) => (
          <NewsItem
            NewsImage={notice.image}
            NewsTitle={notice.title}
            NewsDescription={notice.desc}
            NewsDate={notice.date}
            link={notice.link}
          />
        ))}
      </div>
    );
  }
}
