import React from "react";
import { Drawer } from "antd";
import "react-modern-drawer/dist/index.css";
import { useState } from "react";
import { ReactComponent as ICODrawer } from "./res/Icon_MoreMenu.svg";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { ReactComponent as CIMaihub } from "./res/Main_maihub.svg";
import { ReactComponent as Lunit } from "./res/Logo_Lunit.svg";
import { ReactComponent as AIinsight } from "./res/Logo_AIinsight.svg";
import { ReactComponent as Neurophet } from "./res/Logo_Neurophet.svg";
import { ReactComponent as Promedius } from "./res/Logo_Promedius.svg";
import { ReactComponent as Deepnoide } from "./res/Logo_Deepnoide.svg";
import { ReactComponent as Mediwhale } from "./res/Logo_Mediwhale.svg";
import { ReactComponent as Claripi } from "./res/Logo_Claripi.svg";
import { CloseOutlined } from "@ant-design/icons";
import { Flex, Radio } from "antd";
import { useTranslation } from "react-i18next";
import i18n from "./language/i18n";

export default function DrawerMenu(props) {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      label: <Link to="/">Home</Link>,
    },
    {
      key: "2",
      label: <Link to="/about-us">About maihub</Link>,
    },
    {
      key: "sub1",
      label: "Products",
      children: [
        {
          key: "3",
          label: (
            <Link to="/LunitCXR">
              Chest X-ray
              <Lunit height={16} width={60} />
            </Link>
          ),
        },
        {
          key: "4",
          label: (
            <Link to="/LunitMMG">
              Mammography
              <Lunit height={16} width={60} />
            </Link>
          ),
        },
        {
          key: "5",
          label: (
            <Link to="/AIFAI">
              Fundus
              <AIinsight height={22} width={98} />
            </Link>
          ),
        },
        {
          key: "6",
          label: (
            <Link to="/MCVD">
              Fundus/CVD
              <Mediwhale height={11.5} width={119} />
            </Link>
          ),
        },
        {
          key: "7",
          label: (
            <Link to="/DeepMRA">
              Brain MRA
              <Deepnoide height={15.5} width={103} />
            </Link>
          ),
        },
        {
          key: "8",
          label: (
            <Link to="/DeepCT">
              Lung CT
              <Deepnoide height={15.5} width={103} />
            </Link>
          ),
        },
        {
          key: "9",
          label: (
            <Link to="/DeepSpine">
              Spine X-ray
              <Deepnoide height={15.5} width={103} />
            </Link>
          ),
        },
        {
          key: "10",
          label: (
            <Link to="/ClariLow">
              Low-Dose CT
              <Claripi height={13.5} width={58} />
            </Link>
          ),
        },
        {
          key: "11",
          label: (
            <Link to="/ClariDenoise">
              CT Image Denoising
              <Claripi height={13.5} width={58} />
            </Link>
          ),
        },
        {
          key: "12",
          label: (
            <Link to="/NeuroMRI">
              MRI Quantification
              <Neurophet height={24} width={97} style={{ marginTop: "-4px" }} />
            </Link>
          ),
        },
        {
          key: "13",
          label: (
            <Link to="/PromediusCT">
              Abdominal CT
              <Promedius height={24} width={106} />
            </Link>
          ),
        },
        {
          key: "13",
          label: (
            <Link to={i18n.language === "ko" ? "/Brochure" : "/BrochureEN"} target="_blank">
              e-Brochure (All Solutions)
            </Link>
          ),
        },
      ],
    },

    {
      key: "14",
      label: <Link to="/news">News & Events</Link>,
    },
    {
      key: "15",
      label: (
        <button onClick={props.OpenModal} className="Drawer-btn">
          {t("header-t1")}
        </button>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [collapsed, setCollapsed] = useState(false);

  const onChangeLang = () => {
    // i18n.language === "ko"
    //   ? i18n.changeLanguage("en")
    //   : i18n.changeLanguage("ko");
    switch (i18n.language) {
      case "ko":
        i18n.changeLanguage("en");
        localStorage.setItem('language', "en");
        break;
      case "en":
        i18n.changeLanguage("ko");
        localStorage.setItem('language', "ko");
        break;
      default:
        i18n.changeLanguage("en");
        localStorage.setItem('language', "en");
    }
  };

  return (
    <div className="Drawer-container">
      <button className="Btn-lang Btn-drawer" onClick={showDrawer}>
        <ICODrawer width={16} height={16} />
      </button>
      <Drawer
        closable={false}
        onClose={onClose}
        open={open}
        title={<CIMaihub height={24} width={136} />}
        extra={
          <button className="ant-draw-close" onClick={onClose}>
            <CloseOutlined />
          </button>
        }
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Menu
              selectable={false}
              mode="inline"
              inlineCollapsed={collapsed}
              items={items}
              onClick={onClose}
            />
            <Flex vertical gap="middle" style={{ padding: " 12px 24px" }}>
              <Radio.Group defaultValue={i18n.language} buttonStyle="solid">
                <Radio.Button
                  value="ko"
                  onChange={() => {
                    i18n.changeLanguage("ko");
                    localStorage.setItem('language', "ko");
                  }}
                >
                  KR
                </Radio.Button>
                <Radio.Button
                  value="en"
                  onChange={() => {
                    i18n.changeLanguage("en");
                    localStorage.setItem('language', "en");
                  }}
                >
                  EN
                </Radio.Button>
              </Radio.Group>
            </Flex>
          </div>
          <div
            style={{
              padding: "24px",
              bottom: "0",
              background: "white",
              borderTop: " 1px solid rgba(5, 5, 5, 0.06)",
              marginTop: "24px",
            }}
          >
            <p
              style={{
                fontSize: "0.8rem",
                lineHeight: "1.8",
                fontWeight: "500",
              }}
            >
              <span style={{ fontSize: "1rem" }}>
                {t("footer-t1")}
                {/* (주) 마이허브 */}
              </span>
              <br />
              contacts@maihub.ai
              <br />
              {t("footer-t2")}
              {/* 서울특별시 서초구 바우뫼로37길 58, 4층 */}
              <br />
              {t("footer-t3")}
              {/* Tel : 02.6949.1867 | Fax : 02.6949.1868 */}
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
