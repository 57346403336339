import React from "react";
import "./News.css";
import NewsList from "./NewsList";
import { useTranslation } from "react-i18next";

export default function LatestNews() {
  const { t } = useTranslation();
  return (
    <div className="News-container-back">
      <div className="News-container">
        <div className="News-content">
          <p className="News-main-title" data-aos="fade-up">
            {t("news-latest-t1")}
            {/* 마이허브의 다양한 소식을 만나보세요 */}
          </p>
          <p className="Default-title" data-aos="fade-up">
            {t("news-latest-t2")}
            {/* 최근 소식 */}
          </p>
          <div
            className="Default-seperator"
            style={{ marginBottom: "8vh" }}
            data-aos="fade-up"
          />
          <NewsList />
        </div>
      </div>
    </div>
  );
}
