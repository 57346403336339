import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import MediFAI from "../res/Product_MCVD.png";
import { ReactComponent as Mediwhale } from "../res/Logo_Mediwhale.svg";
import MediHigh from "../res/Medi_High.png";
import MediModerate from "../res/Medi_Moderate.png";
import MediNormal from "../res/Medi_Normal.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function MediCVDPage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("medi-cvd-t1")}
            {/* FUNDUS 안저 촬영 영상 검출 및 */}
            <br />
            {t("medi-cvd-t2")}
            {/* CVD 예측 솔루션 */}
          </p>
          <div className="Product-logo">
            <Mediwhale width={140} height={14} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("medi-cvd-t3")}
              {/* 안과영상검출·진단보조소프트웨어(3등급) */}
              <br />
              {t("medi-cvd-t4")}
              {/* 심혈관위험평가소프트웨어(3등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={MediFAI} className="Product-image" />
        </div>
        <p className="Product-text-Inno" data-aos="fade-up">
          {t("medi-cvd-t5")}
          {/* ※ 평가유예 신의료기술로 선정되어 비급여 적용 가능합니다. */}
        </p>
        <div className="Product-text-container">
          <p className="Product-text" data-aos="fade-up">
            {t("medi-cvd-t6")}
            {/* 안저 이상 및 질병 검출 보조 */}
          </p>
        </div>

        <div className="Finding-group" style={{ columnGap: "14px" }}>
          <div className="Finding" style={{ flex: 1 }} data-aos="fade-up">
            {t("medi-cvd-t7")}
            {/* 백내장 */}
          </div>
          <div className="Finding" style={{ flex: 1 }} data-aos="fade-up">
            {t("medi-cvd-t8")}
            {/* 녹내장 */}
          </div>
          <div className="Finding" style={{ flex: 1 }} data-aos="fade-up">
            {t("medi-cvd-t9")}
            {/* 망막질환 */}
          </div>
        </div>

        <div className="Product-text-container">
          <p className="Product-text" data-aos="fade-up">
            {t("medi-cvd-t10")}
            {/* 간단한 눈 검사로 */}
            <span className="Text-point">
              <br className={i18n.language === "ko" ? "min-show768 " : ""} />
              {t("medi-cvd-t11")}
              {/* 심혈관 질환 발생 위험도 */}
              <br className={i18n.language === "ko" ? "hidden " : ""} />
            </span>
            {t("medi-cvd-t12")}
            {/* 예측 */}
          </p>
        </div>

        <div
          className="Product-desc-container"
          style={{ columnGap: "14px", margin: "0" }}
        >
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={MediNormal}
              className="Product-image"
              style={{ maxWidth: "300px" }}
            />
            <p
              className="Text-c"
              style={{
                color: "#2EB200",
                fontSize: "1.4rem",
                fontWeight: "800",
                margin: "16px 0",
                width: "100%",
                padding: "4px 0,",
                height: "46px",
                borderBottom: "2px solid #2EB200",
                maxWidth: "300px",
              }}
            >
              {t("medi-cvd-t13")}
              {/* 저위험군 */}
            </p>
            <p style={{ maxWidth: "300px" }}>
              {t("medi-cvd-t14")}
              {/* 관상동맥석회가 없는 것과 같은 수준의 심혈 위험으로 판단가능 */}
            </p>
          </div>
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={MediModerate}
              className="Product-image"
              style={{ maxWidth: "300px" }}
            />
            <p
              className="Text-c"
              style={{
                color: "#F36600",
                fontSize: "1.4rem",
                fontWeight: "800",
                margin: "16px 0",
                width: "100%",
                padding: "4px 0,",
                height: "46px",
                borderBottom: "2px solid #F36600",
                maxWidth: "300px",
              }}
            >
              {t("medi-cvd-t15")}
              {/* 중증도위험군 */}
            </p>
            <p style={{ maxWidth: "300px" }}>
              {t("medi-cvd-t16")}
              {/* 관상동맥석회화 지수가 있으나 100이하인 것과 비슷한 수준의 심혈관
              위험으로 판단 */}
            </p>
          </div>
          <div className="Product-item-container" data-aos="fade-up">
            <img
              src={MediHigh}
              className="Product-image"
              style={{ maxWidth: "300px" }}
            />
            <p
              className="Text-c"
              style={{
                color: "#EB0000",
                fontSize: "1.4rem",
                fontWeight: "800",
                margin: "16px 0",
                width: "100%",
                padding: "4px 0,",
                height: "46px",
                borderBottom: "2px solid #EB0000",
                maxWidth: "300px",
              }}
            >
              {t("medi-cvd-t17")}
              {/* 고위험군 */}
            </p>
            <p style={{ maxWidth: "300px" }}>
              {t("medi-cvd-t18")}
              {/* 관상동맥석회화 지수가 100이상인 것과 비슷한 수준의 심혈관 위험으로
              판단 */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
