import React from "react";
import "./News.css";
import YouTube from "react-youtube";

export default function VideoItem(props) {
  return (
    <div className="Video-item-container">
      <div className="Video-container" data-layer="0">
        <iframe
          style={{ borderRadius: "4px" }}
          width="100%"
          height="206"
          src={props.videoURL}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <p className="Video-title">{props.videoTitle}</p>
        <p className="Video-tag">{props.videoTag}</p>
      </div>
    </div>
  );
}
