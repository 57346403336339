import React from "react";
import "../App.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

export default function SolutionImage(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-delay={props.delay}
      className="Solution-container"
    >
      <Link to={props.link}>
        <div className="Solution-image-container">
          <img className="Solution-image" src={props.image} alt={props.title} />
        </div>
      </Link>
      <div className="Solution-title-container">
        {props.logo}
        <p className="Solution-title">{props.title}</p>
      </div>
      <p className="Solution-description">{props.description}</p>
      <div className={props.visible ? "Badge-text" : "hidden"}>
        {"* 비급여 적용"}
      </div>
    </div>
  );
}
