import React, { useState } from "react";
import { Input, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as MaiLink } from "./res/Main_maiLink.svg";
import LCXR from "./res/Solution_Lunit_CXR.png";
import LMMG from "./res/Solution_Lunit_MMG.png";
import MFAI from "./res/Solution_Mediwhale_FAI.png";
import MCVD from "./res/Solution_Mediwhale_CVD.png";
import WFAI from "./res/Solution_Whisky_FAI.png";
import DeepMRA from "./res/Solution_Deep_MRA.png";
import DeepLung from "./res/Solution_Deep_CT.png";
import DeepSpine from "./res/Solution_Deep_Spine.png";
import ClariCT from "./res/Solution_Clari_LowDose.png";
import ClariDenois from "./res/Solution_Clari_Denois.png";
import NeuroMRI from "./res/Solution_Neuro_MRI.png";
import PromedCT from "./res/Solution_Promed_CT.png";
import TalosPrediction from "./res/Solution_Talos_Prediction.png";
import SelvasPrediction from "./res/Solution_Selvas_Prediction.png";
import { ReactComponent as Lunit } from "./res/Logo_Lunit.svg";
import { ReactComponent as AIinsight } from "./res/Logo_AIinsight.svg";
import { ReactComponent as Neurophet } from "./res/Logo_Neurophet.svg";
import { ReactComponent as Promedius } from "./res/Logo_Promedius.svg";
import { ReactComponent as Deepnoide } from "./res/Logo_Deepnoide.svg";
import { ReactComponent as Mediwhale } from "./res/Logo_Mediwhale.svg";
import { ReactComponent as Claripi } from "./res/Logo_Claripi.svg";
import { Modal } from "antd";

const { TextArea } = Input;

export default function ContactUs({ handleCancel }) {
  const { t } = useTranslation();
  const [hospital, setHospital] = useState("");
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const [selectedSolutions, setSelectedSolutions] = useState([]);

  const handleSolutionChange = (solutionId) => {
    const isSelected = selectedSolutions.includes(solutionId);
    const updatedSelection = isSelected
      ? selectedSolutions.filter((id) => id !== solutionId)
      : [...selectedSolutions, solutionId];
    setSelectedSolutions(updatedSelection);
  };

  const handleHospitalChange = (event) => {
    setHospital(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAreaChange = (event) => {
    setArea(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  function messagePopup(msg) {
    Modal.info({
      content: msg,
    });
  }

  const base64encode = (str) => {
    const utf8String = encodeURIComponent(str);
    const hospital64 = btoa(utf8String);
    return hospital64;
  };

  const handleOk = () => {
    if (!hospital) {
      messagePopup(t("contact-msg1"));
      return;
    }

    if (!name) {
      messagePopup(t("contact-msg2"));
      return;
    }

    if (!area) {
      messagePopup(t("contact-msg3"));
      return;
    }

    if (!email) {
      messagePopup(t("contact-msg4"));
      return;
    }

    if (!phone) {
      messagePopup(t("contact-msg5"));
      return;
    }

    if (!content) {
      messagePopup(t("contact-msg6"));
      return;
    }

    const selectedInterest = solutions.filter((solution) =>
      selectedSolutions.includes(solution.id)
    );
    const arInterestText = selectedInterest
      .map((solution) => solution.text)
      .join(", ");
    const interest64 = base64encode(arInterestText);
    const hospital64 = base64encode(hospital);
    const name64 = base64encode(name);
    const area64 = base64encode(area);
    const email64 = base64encode(email);
    const phone64 = base64encode(phone);
    const content64 = base64encode(content);

    const formData = new FormData();
    formData.append("hospital", hospital64);
    formData.append("name", name64);
    formData.append("area", area64);
    formData.append("email", email64);
    formData.append("phone", phone64);
    formData.append("content", content64);
    formData.append("interest", interest64);

    fetch("https://maihubdev.cafe24.com/homepage/mailapi.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          // If the response is OK (status code 200), return success
          // console.log("Mail sent successfully");
          messagePopup(t("contact-msg7"));
          setHospital("");
          setName("");
          setArea("");
          setEmail("");
          setPhone("");
          setContent("");
          handleCancel();
          // Add your logic here, maybe show a success message to the user
        } else {
          // If the response status is not OK, handle the error
          // console.error('Mail sending failed:', response.statusText);
          messagePopup(t("contact-msg8"));
          // Add your logic to handle the failure, maybe show an error message to the user
        }
      })
      .catch((error) => {
        // If there is a network error or other issue, catch it here
        // console.error('Error sending mail:', error);
        messagePopup(t("contact-msg9"));
        // Add your logic to handle the error, maybe show an error message to the user
      });
  };

  return (
    <div>
      <p className="Modal-title">
        {t("contact-t1")}
        {/* 문의사항을 남겨주세요 */}
      </p>
      <p style={{ marginBottom: "30px", fontSize: "0.95rem" }}>
        {t("contact-t2")}
        {/* 마이허브에 관심 가져주셔서 감사합니다.  */}
        <br />
        {t("contact-t3")}
        {/* 제품에 관한 사항, 영업, 기술지원 등 문의를 남겨주시면 */}
        <br />
        {t("contact-t4")}
        {/* 빠른 시일 내에 연락드리겠습니다. */}
      </p>
      <div className="Form-container">
        <Input
          placeholder={t("contact-t5")}
          value={hospital}
          onChange={handleHospitalChange}
        />
        {/* 병원명 */}
        <Input
          placeholder={t("contact-t6")}
          value={name}
          onChange={handleNameChange}
        />
        {/* 이름 */}
        <Input
          placeholder={t("contact-t7")}
          value={area}
          onChange={handleAreaChange}
        />
        {/* 지역* (ex.서울시 서초구) */}
        <Input
          placeholder={t("contact-t8")}
          value={email}
          onChange={handleEmailChange}
        />
        {/* 이메일* */}
        <Input
          placeholder={t("contact-t9")}
          value={phone}
          onChange={handlePhoneChange}
        />
        {/* 연락처 */}
        <TextArea
          placeholder={t("contact-t10")}
          autoSize={{ minRows: 3, maxRows: 5 }}
          value={content}
          onChange={handleContentChange}
        />
        {/* 문의사항 */}
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: "700",
            margin: "20px 0  0",
          }}
        >
          {t("contact-t11")}
          {/* ※ 관심 솔루션을 선택해 주세요. */}
        </p>
        <CheckSolution
          selectedSolutions={selectedSolutions}
          onSolutionChange={handleSolutionChange}
        />
        <button className="Modal-submit" key="submit" onClick={handleOk}>
          {t("header-t1")}
          {/* 문의하기 */}
        </button>
      </div>
    </div>
  );
}

const solutions = [
  {
    id: 1,
    image: LCXR,
    title: "Chest X-ray",
    text: "Lunit:CXR",
    logo: <Lunit height={18} width={54} style={{ marginTop: "12px" }} />,
  },
  {
    id: 2,
    image: LMMG,
    title: "Mammography",
    text: "Lunit:MMG",
    logo: <Lunit height={18} width={54} style={{ marginTop: "12px" }} />,
  },
  {
    id: 3,
    image: MFAI,
    title: "Fundus",
    text: "Mediwhale:Fundus",
    logo: <Mediwhale height={14} width={106} style={{ marginTop: "12px" }} />,
  },
  {
    id: 4,
    image: MCVD,
    title: "Fundus for CVD",
    text: "Mediwhale:Fundus for CVD",
    logo: <Mediwhale height={14} width={106} style={{ marginTop: "12px" }} />,
  },
  {
    id: 5,
    image: WFAI,
    title: "Fundus",
    text: "AI Insight:Fundus",
    logo: <AIinsight height={24} width={90} style={{ marginTop: "8px" }} />,
  },
  {
    id: 6,
    image: DeepMRA,
    title: "Brain MRA",
    text: "DEEPNOID:Brain MRA",
    logo: <Deepnoide height={17} width={90} style={{ marginTop: "12px" }} />,
  },
  {
    id: 7,
    image: DeepLung,
    title: "Lung CT",
    text: "DEEPNOID:Lung CT",
    logo: <Deepnoide height={17} width={90} style={{ marginTop: "12px" }} />,
  },
  {
    id: 8,
    image: DeepSpine,
    title: "Spine X-ray",
    text: "DEEPNOID:Spine X-ray",
    logo: <Deepnoide height={17} width={90} style={{ marginTop: "12px" }} />,
  },
  {
    id: 9,
    image: ClariCT,
    title: "Low-Dose CT",
    text: "Clari:Low-Dose CT",
    logo: <Claripi height={15} width={50} style={{ marginTop: "12px" }} />,
  },
  {
    id: 10,
    image: ClariDenois,
    title: "CT Image Denoising",
    text: "Clari:CT Image Denoising",
    logo: <Claripi height={15} width={50} style={{ marginTop: "12px" }} />,
  },
  {
    id: 11,
    image: NeuroMRI,
    title: "MRI Quantification",
    text: "neurophet:MRI Quantification",
    logo: <Neurophet height={22} width={95} style={{ marginTop: "8px" }} />,
  },
  {
    id: 12,
    image: PromedCT,
    title: "Abdominal CT",
    text: "Promedius:Abdominal CT",
    logo: <Promedius height={22} width={105} style={{ marginTop: "10px" }} />,
  },
];

const CheckSolution = (props) => {
  return (
    <div className="modal-check-container">
      {solutions.map((solution) => (
        <div className="modal-checkSolution">
          <img
            src={solution.image}
            style={{ width: "100%", borderRadius: "2px" }}
          />
          {solution.logo}
          <p
            style={{ fontSize: "0.9rem", fontWeight: "700", marginTop: "4px" }}
          >
            {solution.title}
          </p>
          <Checkbox
            style={{
              position: "absolute",
              top: "-8px",
              left: "-8px",
              zIndex: "9999999",
            }}
            checked={props.selectedSolutions.includes(solution.id)}
            onChange={() => props.onSolutionChange(solution.id)}
          />
        </div>
      ))}
    </div>
  );
};
