import React from "react";
import "../App.css";
import IntroduceMailink from "./IntroduceMailink";
import NewsThumbnail from "./NewsThumbnail";
import ScrollIndi from "./ScrollIndi";
import MainBanner from "./MainBanner";
import PlatformCarousel from "./PlatformCarousel";
import BannerPopup from "./BannerPopup";

export default function MainPage() {
  return (
    <div className="container-100">
      <MainBanner />
      <ScrollIndi />
      <IntroduceMailink />
      {/* <PlatformList /> */}
      <PlatformCarousel />
      <NewsThumbnail />
      {/* <BannerPopup /> */}
    </div>
  );
}
