import React from "react";
import { ReactComponent as Instar } from "./res/Icon_Instargram.svg";
import { ReactComponent as Youtube } from "./res/Icon_Youtube.svg";
import { ReactComponent as Linkdin } from "./res/Icon_Linkedin.svg";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="Footer-back">
      <div className="Footer-container">
        <div className="Footer-top">
          <p style={{ fontSize: "1.25rem", fontWeight: "800" }}>CONTACT US</p>
          <div className="Sns-container">
            <a
              href="https://www.instagram.com/maihub_official/"
              target="_blank"
            >
              <Instar color={"white"} />
            </a>
            <a
              href="https://youtube.com/@maihub0609?feature=shared"
              target="_blank"
            >
              <Youtube color={"white"} />
            </a>
            <a
              href="https://www.linkedin.com/company/maihub/mycompany/"
              target="_blank"
            >
              <Linkdin color={"white"} />
            </a>
          </div>
        </div>

        <div className="Footer-bottom">
          <p style={{ fontSize: "0.8rem", lineHeight: "1.8" }}>
            <span style={{ fontSize: "1rem" }}>
              {t("footer-t1")}
              {/* (주)마이허브 */}
            </span>
            <br />
            contacts@maihub.ai
            <br />
            {t("footer-t2")}
            {/* 서울특별시 서초구 바우뫼로37길 58, 4층 */}
            <br />
            {t("footer-t3")}
            {/* Tel : 02 . 6949 . 1867 | Fax : 02 . 6949 . 1868 */}
          </p>
          <p style={{ fontSize: "0.68rem" }}>
            COPYRIGHT 2024. MAIHUB CORPORATION All RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </div>
  );
}
