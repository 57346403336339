import React from "react";

import { useEffect } from "react";
import AIBack from "../res/AI_Back.png";
import AOS from "aos";

export default function ScrollIndi() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="Scroll-indi-back">
      <img src={AIBack} className="Scroll-ai-back" />
      <div className="Scroll-indi-container">
        <p className="Scroll-indi-text" data-aos="fade-up" data-aos-delay="100">
          We deliver cutting-edge healthcare technology faster and more widely
          than anyone else.
        </p>
      </div>
    </div>
  );
}
