import React from "react";
import BannerIMG from "../res/Main_Banner.png";
import BannerIMG02 from "../res/Main_Banner02.png";
import BannerIMG03 from "../res/Main_Banner03.png";
import BannerIMG04 from "../res/Main_Banner04.png";
import { useEffect } from "react";
import video from "../res/main_back.mp4";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";

export default function MainBanner() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="Banner-back"
      // style={{ backgroundImage: `url(${BannerIMG04})` }}
    >
      <video autoPlay loop muted playsInline={true}>
        <source src={video} type="video/mp4" />
      </video>
      {i18n.language === "ko" ? <SloganKO /> : <SloganEN />}
    </div>
  );
}

const SloganKO = () => {
  return (
    <div className="Banner-text-container">
      <div style={{ textAlign: "center" }}>
        <p className="opacity1 text-ani">
          <span style={{ color: "#00B6D1", fontWeight: "900" }}>AI</span>를
        </p>

        <p className="opacity0 text-ani2s">만나는 </p>

        <br className="Banner-text-brShow" />

        <p className="opacity0 text-ani1s">빠른 </p>

        <p className="opacity0 text-ani2s">방법</p>
      </div>
      <p
        style={{
          color: "white",
          fontSize: "2rem",
          fontWeight: "500",
          textAlign: "center",
        }}
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1500"
      >
        EASY <span style={{ color: "#00B6D1" }}>WAY</span> TO MEET
        <span style={{ color: "#00B6D1" }}> AI</span>
      </p>
    </div>
  );
};

const SloganEN = () => {
  return (
    <div className="Banner-text-container">
      <div style={{ textAlign: "center" }}>
        <p className="opacity1 text-ani">EASY</p>

        <p
          style={{ color: "#00B6D1", fontWeight: "900" }}
          className="opacity0 text-ani2s"
        >
          WAY
        </p>

        <br className="Banner-text-brShow" />

        <p className="opacity0 text-ani1s">TO MEET</p>

        <p
          style={{ color: "#00B6D1", fontWeight: "900" }}
          className="opacity0 text-ani2s"
        >
          AI
        </p>
      </div>
      <p
        style={{
          color: "white",
          fontSize: "1.8rem",
          fontWeight: "500",
          textAlign: "center",
        }}
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1500"
      >
        Medical <span style={{ color: "#00B6D1" }}>AI</span> Solution
        <span style={{ color: "#00B6D1" }}> Paltform</span>
      </p>
    </div>
  );
};
