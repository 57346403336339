import React from "react";
import "./News.css";
import VideoItem from "./VideoItem";
import { useTranslation } from "react-i18next";

const videos = [
  {
    videoURL: "https://www.youtube.com/embed/ExRWBWa2EB8?si=DpC4tltU0vrWSs6_",
    videoTitle: "Medical AI solution platform 'maiLink'",
    videoTag: "#maiLink #Medical AI #AI Platform ",
  },
  {
    videoURL: "https://www.youtube.com/embed/sOWjabfPqNQ?si=BXPzx-LQJo7nsctW",
    videoTitle: "Medical AI solution 'maiLink CXR/MMG'",
    videoTag: "#maiLink #Medical AI #Chest X-ray #Mammography",
  },
  {
    videoURL: "https://www.youtube.com/embed/jpAyCii_CNE?si=hSrWLWprZhYyOi0k",
    videoTitle: "Medical AI solution 'maiLink Fundus/CVD'",
    videoTag: "#maiLink #Medical AI #Fundus #CVD",
  },
];

export default function VideoList() {
  const { t } = useTranslation();
  return (
    <div className="News-container-back">
      <div className="News-container">
        <div className="News-content">
          <p className="Default-title" data-aos="fade-up">
            {t("news-video-t1")}
            {/* 영상 소개 자료 */}
          </p>
          <div className="Default-seperator" data-aos="fade-up" />
        </div>
      </div>
      <div
        className="News-container-back"
        style={{ padding: "4vh 0", marginBottom: "2vh" }}
      >
        <div className="News-container">
          <div className="News-content">
            <div className="Video-list-container" data-aos="fade-up">
              {videos.map((video) => (
                <VideoItem
                  videoURL={video.videoURL}
                  videoTitle={video.videoTitle}
                  videoTag={video.videoTag}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://www.youtube.com/channel/UCnEC3MQ1Mew-fxfqFug1MYg"
        className="LineButton"
        target="_blank"
        data-aos="fade-up"
      >
        {t("news-video-t2")}
        {/* 유튜브 채널 바로가기 */}
      </a>
    </div>
  );
}
