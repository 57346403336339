import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ko from "./ko.json";

// 로컬스토리지에 저장하기
const savedLanguage = localStorage.getItem('language'); // 기본 언어를 'en'으로 설정

// 사용자의 브라우저 언어를 가져오는 함수
const getBrowserLanguage = () => {
  let browserLanguage = 'en'; // 기본 언어를 영어로 설정
  if (navigator.languages && navigator.languages.length) {
    browserLanguage = navigator.languages[0];
  } else if (navigator.language) {
    browserLanguage = navigator.language;
  }
  
  // 한국어가 아니면 영어로 설정
  if (!browserLanguage.startsWith('ko')) {
    browserLanguage = 'en';
  }else{
    browserLanguage = 'ko';
  }
  
  console.log('선택된 언어:', browserLanguage);
  return browserLanguage;
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ko: {
      translation: ko,
    },
  },
  lng: savedLanguage || getBrowserLanguage(),
  fallbackLng: {
    "ko-KR": ["ko-KR"],
    default: ["en-US"],
  },
  debug: true,
  defaultNS: "translation",
  ns: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
