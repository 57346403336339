import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Neurophet } from "../res/Logo_Neurophet.svg";
import NeuroMRI from "../res/Product_NeuroMRI.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function NeuroMRIPage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("neuro-mri-t1")}
            {/* MRI 정량화 및 진단 보조 AI 솔루션 */}
          </p>
          <div className="Product-logo">
            <Neurophet width={112} height={28} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("neuro-mri-t2")}
              {/* 뇌영상분석소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={NeuroMRI} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("neuro-mri-t3")}
              {/* 뇌 부피 위축 */}
            </span>
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("neuro-mri-t4")}
              {/* 진단 */}
            </span>
          </p>
          <p
            className="Product-text"
            style={{ marginTop: "4vh" }}
            data-aos="fade-up"
          >
            <span>{t("neuro-mri-t5")}</span>
            <span className="Text-point">
              {t("neuro-mri-t6")}
              {/* 백질변성 척도 */}
            </span>
            {t("neuro-mri-t7")}
            {/* 정밀 분석후 심각도 계산 */}
          </p>
        </div>
      </div>
    </div>
  );
}
