import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import { ReactComponent as CIMaihub } from "./res/Main_maihub.svg";
import { ReactComponent as ICOLanguage } from "./res/Icon_Language.svg";
import MainPage from "./main/MainPage";
import AboutUsPage from "./about/AboutUsPage";
import NewsEventPage from "./news/NewsEventPage";
import Footer from "./Footer";
import HeaderProductMenu from "./product/HeaderProductMenu";
import LunitCXR from "./product/LunitCXRPage";
import LunitMMG from "./product/LunitMMGPage";
import WFAI from "./product/AIInsightFAIPage";
import MCVD from "./product/MediCVDPage";
import DeepMRA from "./product/DeepMRAPage";
import DeepCT from "./product/DeepCTPage";
import DeepSpine from "./product/DeepSpinePage";
import CliriDenoising from "./product/ClariDenoisePage";
import CliriLowDose from "./product/ClariLowDosePage";
import NeuroMRI from "./product/NeuroMRIPage";
import PromediusCT from "./product/PromeAbdominalPage";
import { Modal, ConfigProvider } from "antd";
import { useState } from "react";
import ContactUs from "./ContactUs";
import DrawerMenu from "./DrawerMenu";
import "./App.css";
import "./main/Main.css";
import "./news/News.css";
import "./about/AboutUs.css";
import "./product/Product.css";
import "aos/dist/aos.css";
import "./antd.css";
import i18n from "./language/i18n";
import { useTranslation } from "react-i18next";
import Brochure from "./brochure/Brochure";
import BrochureEN from "./brochure/BrochureEN";
import EghisDetailCXR from "./brochure/EghisDetailCXR";
import EghisDetailMMG from "./brochure/EghisDetailMMG";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  const onChangeLang = () => {
    // i18n.language === "ko"
    //   ? i18n.changeLanguage("en")
    //   : i18n.changeLanguage("ko");
    switch (i18n.language) {
      case "ko":
        i18n.changeLanguage("en");
        localStorage.setItem("language", "en");
        break;
      case "en":
        i18n.changeLanguage("ko");
        localStorage.setItem("language", "ko");
        break;
      default:
        i18n.changeLanguage("en");
        localStorage.setItem("language", "en");
    }
  };

  const Header = () => {
    return (
      <header className="Header-container">
        <div className="App-header ">
          <Link to="/">
            <CIMaihub width={136} height={24} />
          </Link>
          <div className="Header-nav">
            <Link to="/about-us">About maihub</Link>
            <HeaderProductMenu />
            <Link to="/news">News & Events</Link>
            {/* <Link to="/eghisDetailCXR">test</Link>
            <Link to="/eghisDetailMMG">test2</Link> */}
          </div>
          <div className="Header-menu">
            <button className="Btn-lang" onClick={() => onChangeLang()}>
              {t("header-t2")}
              <ICOLanguage />
            </button>
            <button className="Btn-contact" onClick={showModal}>
              {t("header-t1")}
              {/* 문의하기 */}
            </button>
            <Modal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width={860}
              footer={false}
            >
              <ContactUs handleCancel={handleCancel} />
            </Modal>
          </div>
          <DrawerMenu OpenModal={showModal} />
        </div>
      </header>
    );
  };

  const MainLayout = () => {
    return (
      <>
        <Header />
        <div style={{ minHeight: "100%", width: "100%" }}>
          <Outlet />
        </div>
        <Footer />
      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1450B4",
          borderRadius: 2,
        },
      }}
    >
      <div>
        <div style={{ width: "100%", height: "100%" }}>
          <Routes>
            <Route exact path="/Brochure" element={<Brochure />} />
            <Route exact path="/BrochureEN" element={<BrochureEN />} />
            <Route exact path="/eghis-detail-cxr" element={<EghisDetailCXR />} />
            <Route exact path="/eghis-detail-mmg" element={<EghisDetailMMG />} />
          </Routes>
        </div>
        <div className="App">
          <div className="container-100">
            <Routes>
              <Route element={<MainLayout />}>
                <Route exact path="/" element={<MainPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/news" element={<NewsEventPage />} />
                <Route path="/LunitCXR" element={<LunitCXR />} />
                <Route path="/LunitMMG" element={<LunitMMG />} />
                <Route path="/AIFAI" element={<WFAI />} />
                <Route path="/MCVD" element={<MCVD />} />
                <Route path="/DeepMRA" element={<DeepMRA />} />
                <Route path="/DeepCT" element={<DeepCT />} />
                <Route path="/DeepSpine" element={<DeepSpine />} />
                <Route path="/ClariLow" element={<CliriLowDose />} />
                <Route path="ClariDenoise" element={<CliriDenoising />} />
                <Route path="/NeuroMRI" element={<NeuroMRI />} />
                <Route path="/PromediusCT" element={<PromediusCT />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default App;
