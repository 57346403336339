import React from "react";
import AOS from "aos";
import "../App.css";
import { useEffect } from "react";

export default function NewsItem(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <a
      href={props.link}
      data-aos="fade-up"
      target="_blank"
      className="News-item-container"
    >
      <img className="News-image" src={props.NewsImage} alt={props.NewsTitle} />
      <div className="News-text-container">
        <div>
          <p className="News-title">{props.NewsTitle}</p>
          <p className="News-desc">{props.NewsDescription}</p>
        </div>
        <p className="News-date">{props.NewsDate}</p>
      </div>
    </a>
  );
}
