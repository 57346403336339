import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Promedius } from "../res/Logo_Promedius.svg";
import PromeAbdominal from "../res/Product_PromeAbdominal.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function PromeAbdominalPage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("promedi-abdomen-t1")}
            <br />
            {t("promedi-abdomen-t2")}
            {/* 근육 및 지방 면적 자동 분할 및 측정 AI 솔루션 */}
          </p>
          <div className="Product-logo">
            <Promedius width={124} height={28} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("promedi-abdomen-t3")}
              {/* 의료영상분석소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={PromeAbdominal} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("promedi-abdomen-t4")}
              {/* VFA 측정을 통한 */}
            </span>
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("promedi-abdomen-t5")}
              {/* FAT CT 대체 */}
            </span>
          </p>
          <p
            className="Product-text"
            style={{ marginTop: "4vh" }}
            data-aos="fade-up"
          >
            <span>
              {t("promedi-abdomen-t6")}
              {/* 지방 수치 */}
            </span>
            <span>
              {t("promedi-abdomen-t7")}
              {/* 정량화 및 */}
            </span>
            <span className="Text-point">
              {t("promedi-abdomen-t8")}
              {/* 근육 양, 질 정량 지표 */}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
