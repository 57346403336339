import React from "react";
import "./News.css";
import LatestNews from "./LatestNews";
import MoreNews from "./MoreNews";
import YoutubeList from "./VideoList";

export default function NewsEventPage() {
  return (
    <div className="container-100" style={{ paddingTop: "120px" }}>
      <LatestNews />
      <MoreNews />
      <YoutubeList />
    </div>
  );
}
