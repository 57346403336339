import React from "react";
import "../App.css";
import { ReactComponent as VisionBack } from "../res/About_Back.svg";
import VisionImage from "../res/Vision_Back.png";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";

export default function Vision() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className="Vision-container-back"
      style={{ backgroundImage: `url(${VisionImage})` }}
    >
      <div className="Vision-container">
        <p className="Vision-title" data-aos="fade-up">
          {t("about-vision-t1")}
          {/* 언제 어디서든 누구나 쉽게 사용할 수 있는 */}
          <br className="Vision-title-br" /> {t("about-vision-t2")}
          {/* 메디컬 AI 솔루션을 제공합니다 */}
        </p>
        <div
          className="Vision-content-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <VisionBack
            className="Vision-back"
            width={630}
            style={{ position: "absolute" }}
          />

          <div className="Vision-text-container">
            <p className="Vision-text-title">
              {t("about-vision-t3")}
              {/* 인간의 건강하고 행복한 삶을 위한 의료기술을 개발 */}
            </p>
            <p className="Vision-text-detaile">
              {t("about-vision-t4")}
              {/* 우리의 목표는 인류의 건강과 행복에 기여하며, */}
              <br />
              {t("about-vision-t5")}
              {/* 끊임없는 혁신을 통해 더 나은 미래를 만들어 나가는 것입니다. */}
            </p>
          </div>
          <div className="Vision-text-container left280">
            <p className="Vision-text-title">
              {t("about-vision-t6")}
              {/* 제약없는 인공지능 의료서비스 플랫폼을 구축 */}
            </p>
            <p className="Vision-text-detaile">
              {t("about-vision-t7")}
              {/* 우리의 미션은 의료 혁신과 서비스의 접근성을 높여 더 나은 건강을
              실현하는데 기여하는 것입니다. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
