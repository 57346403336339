import React from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import { useEffect } from "react";
import { ReactComponent as MaiLink } from "../res/Main_maiLink.svg";
import { ReactComponent as Cloud } from "../res/Main_Cloud.svg";
import { ReactComponent as Service } from "../res/Main_Service.svg";
import { ReactComponent as PACS } from "../res/Main_PACS.svg";
import BackgroundImage from "../res/Main_MaiLink_Back.png";
import AOS from "aos";

export default function IntroduceMailink() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container-100">
      <div
        className="Introduce-container-back"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="Introduce-content-container">
          <div data-aos="fade-up">
            <MaiLink width={280} height={70} />
          </div>
          <p
            className="Introduce-title"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {t("intro-t1")}
            {/* 고객의 사용 환경 변경 없이 고객 맞춤 인공지능 진단 보조 솔루션
            제공합니다. */}
          </p>
          <p
            className="Introduce-description"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {t("intro-t2")}
            {/* 우리의 의료 AI 솔루션 플랫폼 서비스는 메디컬 AI의 활용 접근성을
            높여줍니다. 환자 진료부터 의료 데이터 관리까지, 혁신적인 기술로
            지원되는 우수한 서비스를 마이링크에서 경험하세요. 마이링크로 신속한
            의료 결정을 내리고, 환자들의 건강을 지속적으로 관리하세요.
            월구독으로 보다 효율적인 의료 AI 서비스를 경험해보세요. */}
          </p>
          <div
            className="Seperator"
            style={{ marginTop: "14vh" }}
            data-aos="fade-up"
            data-aos-delay="100"
          />
        </div>
      </div>

      <div className="Introduce-detail-container">
        {/* cloud */}
        <div className="Introduce-detail-content">
          <div className="Introduce-detail-icon" data-aos="fade-right">
            <Cloud />
          </div>
          <div
            className="Introduce-detail-text"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <p className="Introduce-detail-title">
              {t("intro-t3")}
              {/* Cloud AI 서비스를 제공합니다.​ */}
            </p>
            <p className="Introduce-detail-description">
              {t("intro-t4")}
              {/* 사용자는 필요한 AI 솔루션을 선택하여 하나의 플랫폼에서 이용할 수
              있습니다. 우리의 플랫폼은 다양한 AI 기술을 효율적으로 관리하고
              제공하여 사용자들이 원하는 기능을 쉽게 활용할 수 있도록
              지원합니다. */}
            </p>
          </div>
        </div>
        {/* Service */}

        <div className="Introduce-detail-content">
          <div
            className="Introduce-detail-icon"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <Service />
          </div>
          <div
            className="Introduce-detail-text"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <p className="Introduce-detail-title">
              {t("intro-t5")}
              {/* 고객 맞춤 AI 의료영상 진단 보조 솔루션을 제공합니다.​​ */}
            </p>
            <p className="Introduce-detail-description">
              {t("intro-t6")}
              {/* 다양한 모달리티(예: DR, CR, MMG, Funduscopy 등)에 특화된 영상 진단
              전용 솔루션 서비스를 제공하여 의료진들의 업무 효율을 극대화합니다.
              우리의 의료 AI 플랫폼은 고객의 요구에 맞춰 개발되었으며,
              의료진들이 보다 정확한 진단을 내릴 수 있도록 보조합니다. */}
            </p>
          </div>
        </div>
        {/* PACS */}

        <div className="Introduce-detail-content">
          <div
            className="Introduce-detail-icon"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <PACS />
          </div>
          <div
            className="Introduce-detail-text"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <p className="Introduce-detail-title">
              {t("intro-t7")}
              {/* PACS 서비스를 제공​합니다. */}
            </p>
            <p className="Introduce-detail-description">
              {t("intro-t8")}
              {/* 기존 PACS 시스템과의 연동 개발 및 추가 비용 없이 우리의 서비스를
              이용하세요. 우리는 사용자들에게 편의성을 제공하기 위해 기존 PACS
              시스템과의 원활한 통합을 지원합니다. 사용자들은 별도의 사용료 없이
              우리의 PACS 서비스를 이용하여 의료 영상 데이터를 효과적으로
              관리하고 공유할 수 있습니다. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
