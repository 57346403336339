import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Claripi } from "../res/Logo_Claripi.svg";
import ClariLowDow from "../res/Product_ClariLowDose.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function ClariLowDosePage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("clari-lowdose-t1")}
            {/* 저선량 CT 골밀도 분석 보조 AI 솔루션 */}
          </p>
          <div className="Product-logo">
            <Claripi width={74} height={18} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("clari-lowdose-t2")}
              {/* 정량적전산화단층촬영골밀도측정기(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={ClariLowDow} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("clari-lowdose-t3")}
              {/* 척추 소주골 부위 */}
            </span>
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("clari-lowdose-t4")}
              {/* 만 선택적으로 자동 측정하여 */}
            </span>
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("clari-lowdose-t5")}
              {/* 골다공증 */}
            </span>
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("clari-lowdose-t6")}
              {/* 진단 보조 */}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
