import React from "react";
import "../App.css";
import { ReactComponent as Lunit } from "../res/Logo_Lunit.svg";
import { ReactComponent as Vuno } from "../res/Logo_Vuno.svg";
import { ReactComponent as AIinsight } from "../res/Logo_AIinsight.svg";
import { ReactComponent as Neurophet } from "../res/Logo_Neurophet.svg";
import { ReactComponent as Promedius } from "../res/Logo_Promedius.svg";
import { ReactComponent as Deepnoide } from "../res/Logo_Deepnoide.svg";
import { ReactComponent as Mediwhale } from "../res/Logo_Mediwhale.svg";
import { ReactComponent as Claripi } from "../res/Logo_Claripi.svg";
import { ReactComponent as Talos } from "../res/Logo_Talos.svg";
import { ReactComponent as Selvas } from "../res/Logo_Selvas.svg";
import { useTranslation } from "react-i18next";

export default function Partner() {
  const { t } = useTranslation();

  return (
    <div className="Partner-container-back">
      <div className="History-container">
        <div className="History-content-container">
          <div className="History-content">
            <p className="Default-title" data-aos="fade-up">
              Our Partner
            </p>
            <div className="Default-seperator" data-aos="fade-up" />
            <p style={{ fontSize: "1.15rem" }} data-aos="fade-up">
              {t("about-partner-t1")}
              {/* 마이허브는 각 분야별 최고의 파트너와 함께 합니다. */}
            </p>
            <div
              className="partner-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <Lunit className="Partner-logo" />
              <Vuno className="Partner-logo" />
              <AIinsight className="Partner-logo" />
              <Neurophet className="Partner-logo" />
              <Promedius className="Partner-logo" />
              <Deepnoide className="Partner-logo" />
              <Mediwhale className="Partner-logo" />
              <Claripi className="Partner-logo" />
              <Talos className="Partner-logo" />
              <Selvas className="Partner-logo" />
              <div className="Partner-logo" />
              <div className="Partner-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
