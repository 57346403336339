import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as Deepnoide } from "../res/Logo_Deepnoide.svg";
import DeepMRA from "../res/Product_DeepMRA.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function DeepMRAPage() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("deep-mra-t1")}
            <br className={i18n.language === "ko" ? "hidden " : ""} />
            <span>{t("deep-mra-t2")}</span>

            {/* 뇌 MRA 영상 검출 및 진단 보조 솔루션 */}
          </p>
          <div className="Product-logo">
            <Deepnoide width={130} height={20} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("deep-mra-t3")}
              {/* 의료영상검출·보조소프트웨어(2등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container">
          <img src={DeepMRA} className="Product-image" data-aos="fade-up" />
        </div>
        <p className="Product-text-Inno" data-aos="fade-up">
          {t("deep-mra-t4")}
          {/* ※ 혁신의료기술로 지정되어 비급여 적용 가능합니다. */}
        </p>
        <div className="Product-text-container">
          <p className="Product-text" data-aos="fade-up">
            <span className={i18n.language === "ko" ? "Text-point" : ""}>
              {t("deep-mra-t5")}
              {/* 뇌동맥류 */}
            </span>
            <br
              className={i18n.language === "ko" ? "hidden " : "min-hidden576"}
            />
            <span className={i18n.language === "ko" ? "" : "Text-point"}>
              {t("deep-mra-t6")}
              {/* 의심 부위 검출 보조 */}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
