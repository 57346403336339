import React from "react";
import "../App.css";
import HistoryBack from "../res/About_History.png";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";

export default function History() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="History-container-back">
      <div className="History-container">
        <div className="History-content-container">
          <div className="History-content">
            <p className="Default-title" data-aos="fade-up">
              Our History
            </p>
            <div className="Default-seperator" data-aos="fade-up" />
            <p style={{ fontSize: "1.15rem" }} data-aos="fade-up">
              {t("about-history-t1")}
              {/* 더 쉽고 효율적인 인공지능 활용을 위해 마이허브는 노력하고 있습니다. */}
            </p>
          </div>
        </div>
        <div
          className="History-timeline-back"
          style={{
            backgroundImage: `url(${HistoryBack})`,
          }}
        >
          <div className="History-content History-row">
            <div className="History-timeline-container">
              <p className="History-year" data-aos="fade-up">
                2024
              </p>
              <div
                className="History-text"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <p>
                  {t("about-history-t12")}
                  {/* - 중소벤처기업부 주관 ‘팁스(TIPS)’ 선정 */}
                </p>
                <p>
                  {t("about-history-t11")}
                  {/* - 말레이시아 MDA 인증 */}
                </p>
              </div>
            </div>

            <div className="History-timeline-container">
              <p className="History-year" data-aos="fade-up">
                2023
              </p>
              <div
                className="History-text"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <p>
                  {t("about-history-t10")}
                  {/* - ‘23년 초기창업패키지 과제 선정 */}
                </p>
                <p>
                  {t("about-history-t9")}
                  {/* - GMP 적합 인증 취득 */}
                </p>
                <p>
                  {t("about-history-t8")}
                  {/* - ‘23.08.18 InsightVue MFDS 인증 (의료영상 전송 SW) */}
                </p>
                <p>
                  {t("about-history-t7")}
                  {/* - Pre A 투자유치 */}
                </p>
                <p>
                  {t("about-history-t6")}
                  {/* - ISO13485:2016 인증 취득 */}
                </p>
                <p>
                  {t("about-history-t5")}
                  {/* - 벤처기업 인증 */}
                </p>
              </div>
            </div>

            <div className="History-timeline-container">
              <p className="History-year" data-aos="fade-up">
                2022
              </p>
              <div
                className="History-text"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <p>
                  {t("about-history-t4")}
                  {/* - 기업부설연구소 설립 */}
                </p>
                <p>
                  {t("about-history-t3")}
                  {/* - 의료기기 제조업 허가 및 품목신고 (mailink) */}
                </p>
                <p>
                  {t("about-history-t2")}
                  {/* - 신용보증기금 혁신스타트업 성장지원 프로그램 선정 */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
