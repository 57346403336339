import React from "react";
import EN1 from "../res/brochure/en/Brochure.jpg";
import EN2 from "../res/brochure/en/Brochure2.jpg";
import EN3 from "../res/brochure/en/Brochure3.jpg";
import EN4 from "../res/brochure/en/Brochure4.jpg";
import EN5 from "../res/brochure/en/Brochure5.jpg";
import EN6 from "../res/brochure/en/Brochure6.jpg";
import EN7 from "../res/brochure/en/Brochure7.jpg";
import EN8 from "../res/brochure/en/Brochure8.jpg";
import EN9 from "../res/brochure/en/Brochure9.jpg";
import EN10 from "../res/brochure/en/Brochure10.jpg";
import EN11 from "../res/brochure/en/Brochure11.jpg";
import EN12 from "../res/brochure/en/Brochure12.jpg";
import EN13 from "../res/brochure/en/Brochure13.jpg";
import { Carousel } from "react-responsive-carousel";

export default function BrochureEN() {
  const en = [
    { image: EN1 },
    { image: EN2 },
    { image: EN3 },
    { image: EN4 },
    { image: EN5 },
    { image: EN6 },
    { image: EN7 },
    { image: EN8 },
    { image: EN9 },
    { image: EN10 },
    { image: EN11 },
    { image: EN12 },
    { image: EN13 },
  ];

  return (
    <div className="brochure-container">
      <Carousel showThumbs={false} autoPlay={true}>
        {en.map((en) => (
          <div>
            <img src={en.image} class="img" alt="e-brochure" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
