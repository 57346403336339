import React from "react";
import AOS from "aos";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as AIInsightLogo } from "../res/Logo_AIinsight.svg";
import WFAI from "../res/Product_WFAI.png";
import AMD from "../res/Arc_AMD.png";
import DR from "../res/Arc_DR.png";
import GLC from "../res/Arc_GLC.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";

export default function AIInsightFAIPage() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            {t("ai-fai-t1")}
            <br />
            {/* FUNDUS 안저 촬영 영상 검출 및*/}
            {t("ai-fai-t2")}
            {/* 진단 보조 솔루션 */}
          </p>
          <div className="Product-logo">
            <AIInsightLogo width={123} height={28} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("ai-fai-t3")}
              {/* 안과영상검출·진단보조소프트웨어(3등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={WFAI} className="Product-image" />
        </div>
        <p className="Product-text-Inno" data-aos="fade-up">
          {t("ai-fai-t4")}
          {/* ※ 혁신의료기술로 지정되어 비급여 적용 가능합니다. */}
        </p>
        <div className="Product-text-container" style={{ marginBottom: "4vh" }}>
          <p className="Product-text" data-aos="fade-up">
            {t("ai-fai-t5")}{" "}
            <br className={i18n.language === "ko" ? "hidden" : ""} />
            {/* 국내 최초 */}
            <span className="Text-point">
              {t("ai-fai-t6")}
              {/* 3종 망막 질환 */}
            </span>
            {/* AI 진단 */}
          </p>
        </div>

        <div
          className="Product-desc-container"
          style={{ columnGap: "20px" }}
          data-aos="fade-up"
        >
          <div className="Product-item-container">
            <div className="W-fai">
              <p
                className={
                  i18n.language === "ko" ? " W-fai-title" : "W-fai-title-en"
                }
              >
                {t("ai-fai-t8")}
                {/* 황반변성 */}
                <br />
                <span style={{ fontSize: "1rem" }}> (AMD)</span>
              </p>
              <img
                src={AMD}
                className="Product-image"
                style={{ maxWidth: "150px", marginTop: "30px" }}
              />
            </div>
            <p className="Text-c">
              {t("ai-fai-t9")}
              {/* 망막 중심 황반부 이상으로 */}
              <br
                className={i18n.language === "ko" ? "min-hidden768" : "hidden"}
              />
              {t("ai-fai-t10")}
              {/* 시력 감소 발생 */}
            </p>
          </div>
          <div className="Product-item-container">
            <div className="W-fai">
              <p
                className={
                  i18n.language === "ko" ? " W-fai-title" : "W-fai-title-en"
                }
              >
                {t("ai-fai-t11")}
                {/* 당뇨망막병증 */}
                <br />
                <span style={{ fontSize: "1rem" }}>(DR)</span>
              </p>
              <img
                src={DR}
                className="Product-image"
                style={{ maxWidth: "150px", marginTop: "30px" }}
              />
            </div>
            <p className="Text-c">
              {t("ai-fai-t12")}
              {/* 당뇨로 인한 높은 혈당이 */}
              <br
                className={i18n.language === "ko" ? "min-hidden768" : "hidden"}
              />
              {t("ai-fai-t13")}
              {/* 망막의 혈관을 손상시켜 발생 */}
            </p>
          </div>
          <div className="Product-item-container">
            <div className="W-fai">
              <p
                className={
                  i18n.language === "ko" ? " W-fai-title" : "W-fai-title-en"
                }
              >
                {t("ai-fai-t14")}
                {/* 녹내장 */}
                <br />
                <span style={{ fontSize: "1rem" }}>(GLC)</span>
              </p>
              <img
                src={GLC}
                className="Product-image"
                style={{ maxWidth: "150px", marginTop: "30px" }}
              />
            </div>
            <p className="Text-c">
              {t("ai-fai-t15")}
              {/* 시신경 손상으로 시야 장애 */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
