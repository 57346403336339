import React from "react";
import EghisMMG from "../res/eghis/eghisMMG.png";
import { Link } from "react-router-dom";
import { ReactComponent as Instar } from "../res/Icon_Instargram.svg";
import { ReactComponent as Youtube } from "../res/Icon_Youtube.svg";
import { ReactComponent as Linkdin } from "../res/Icon_Linkedin.svg";
import { ReactComponent as Home } from "../res/Icon_Home.svg";

export default function EghisDetailCXR() {
  return (
    <div className="banner-footer" style={{ textAlign: "center" }}>
      <img src={EghisMMG} style={{ maxWidth: "1000px", width: "100%" }} />
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "5vh 0 4vh 0",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "1000px",
            margin: "0 6vw",
            flexDirection: "column",
            color: "#003E9C",
          }}
        >
          <div className="Footer-top">
            <p style={{ fontSize: "1.25rem", fontWeight: "900" }}>CONTACT US</p>
            <div className="Sns-container">
              <Link to="/" target="_blank" style={{ color: "#003E9C" }}>
                <Home color={"#003E9C"} />
              </Link>
              <a
                href="https://www.instagram.com/maihub_official/"
                target="_blank"
              >
                <Instar color={"#003E9C"} />
              </a>
              <a
                href="https://youtube.com/@maihub0609?feature=shared"
                target="_blank"
              >
                <Youtube color={"#003E9C"} />
              </a>
              <a
                href="https://www.linkedin.com/company/maihub/mycompany/"
                target="_blank"
              >
                <Linkdin color={"#003E9C"} />
              </a>
            </div>
          </div>

          <div className="Footer-bottom" style={{ textAlign: "start" }}>
            <p style={{ fontSize: "0.8rem", lineHeight: "1.8" }}>
              <span style={{ fontSize: "1rem" }}>(주)마이허브</span>
              <br />
              contacts@maihub.ai
              <br />
              서울특별시 서초구 바우뫼로37길 58, 4층
              <br />
              Tel : 02 . 6949 . 1867 | Fax : 02 . 6949 . 1868
            </p>
            <p style={{ fontSize: "0.68rem" }}>
              COPYRIGHT 2024. MAIHUB CORPORATION All RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
