import React from "react";
import "../App.css";
import Vision from "./Vision";
import History from "./History";
import Partner from "./Partner";

export default function AboutUsPage() {
  return (
    <div className="container-100">
      <Vision />
      <History />
      <Partner />
    </div>
  );
}
