import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import SolutionImage from "./SolutionImage";
import LCXR from "../res/Solution_Lunit_CXR.png";
import LMMG from "../res/Solution_Lunit_MMG.png";
import MFAI from "../res/Solution_Mediwhale_FAI.png";
import MCVD from "../res/Solution_Mediwhale_CVD.png";
import WFAI from "../res/Solution_Whisky_FAI.png";
import DeepMRA from "../res/Solution_Deep_MRA.png";
import DeepLung from "../res/Solution_Deep_CT.png";
import DeepSpine from "../res/Solution_Deep_Spine.png";
import ClariCT from "../res/Solution_Clari_LowDose.png";
import ClariDenois from "../res/Solution_Clari_Denois.png";
import NeuroMRI from "../res/Solution_Neuro_MRI.png";
import PromedCT from "../res/Solution_Promed_CT.png";
import TalosPrediction from "../res/Solution_Talos_Prediction.png";
import SelvasPrediction from "../res/Solution_Selvas_Prediction.png";
import { ReactComponent as Lunit } from "../res/Logo_Lunit.svg";
import { ReactComponent as AIinsight } from "../res/Logo_AIinsight.svg";
import { ReactComponent as Neurophet } from "../res/Logo_Neurophet.svg";
import { ReactComponent as Promedius } from "../res/Logo_Promedius.svg";
import { ReactComponent as Deepnoide } from "../res/Logo_Deepnoide.svg";
import { ReactComponent as Mediwhale } from "../res/Logo_Mediwhale.svg";
import { ReactComponent as Claripi } from "../res/Logo_Claripi.svg";
import { ReactComponent as Talos } from "../res/Logo_Talos.svg";
import { ReactComponent as Selvas } from "../res/Logo_Selvas.svg";
import { useEffect } from "react";
import AOS from "aos";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import I18next from "i18next";
import i18n from "../language/i18n";

export default function PlatformCarousel(props) {
  const { t } = useTranslation();

  const solutions = [
    {
      image: LCXR,
      title: "CXR",
      visible: i18n.language === "ko" ? true : false,
      // desc: "폐질환 판독 보조",
      desc: I18next.t("product-t1"),
      logo: <Lunit height={18} width={68} />,
      link: "/LunitCXR",
      delay: "0",
    },
    {
      image: LMMG,
      title: "MMG",
      visible: false,
      // desc: "유방암 의심 부위 검출 보조",
      desc: I18next.t("product-t2"),
      logo: <Lunit height={18} width={68} />,
      link: "/LunitMMG",
      delay: "0",
    },
    {
      image: MFAI,
      title: "Fundus",
      visible: false,
      // desc: "안저 이상 및 질병 검출 보조",
      desc: I18next.t("product-t3"),
      logo: <Mediwhale height={14} width={139} />,
      link: "/MCVD",
      delay: "0",
    },
    {
      image: MCVD,
      title: "CVD",
      visible: i18n.language === "ko" ? true : false,
      // desc: "심혈관 질환 발생 위험도 예측",
      desc: I18next.t("product-t4"),
      logo: <Mediwhale height={14} width={139} />,
      link: "/MCVD",
      delay: "0",
    },
    {
      image: WFAI,
      title: "Fundus",
      visible: i18n.language === "ko" ? true : false,
      // desc: "3종 망막 질환 검출 보조",
      desc: I18next.t("product-t5"),
      logo: <AIinsight height={24} width={107} />,
      link: "/AIFAI",
      delay: "0",
    },
    {
      image: DeepMRA,
      title: "Brain MRA",
      visible: i18n.language === "ko" ? true : false,
      // desc: "뇌동맥류 의심 부위 검출 보조",
      desc: I18next.t("product-t6"),
      logo: <Deepnoide height={17} width={110} />,
      link: "/DeepMRA",
      delay: "0",
    },
    {
      image: DeepLung,
      title: "Lung CT",
      visible: false,
      // desc: "골밀도 분석 보조",
      desc: I18next.t("product-t7"),
      logo: <Deepnoide height={17} width={110} />,
      link: "/DeepCT",
      delay: "0",
    },
    {
      image: DeepSpine,
      title: "Spine X-ray",
      visible: false,
      // desc: "척추 측만 각도 측정 및 압박골절 보조",
      desc: I18next.t("product-t8"),
      logo: <Deepnoide height={17} width={110} />,
      link: "/DeepSpine",
      delay: "0",
    },
    {
      image: ClariCT,
      title: "Low-Dose CT",
      visible: false,
      // desc: "골밀도 분석 보조",
      desc: I18next.t("product-t9"),
      logo: <Claripi height={15} width={62} />,
      link: "/ClariLow",
      delay: "0",
    },
    {
      image: ClariDenois,
      title: "CT Image Denoising",
      visible: false,
      // desc: "선명한 이미지 획득",
      desc: I18next.t("product-t10"),
      logo: <Claripi height={15} width={62} />,
      link: "/ClariDenoise",
      delay: "0",
    },
    {
      image: NeuroMRI,
      title: "MRI Quantification",
      visible: false,
      // desc: "뇌 위축 백질 변성 분석",
      desc: I18next.t("product-t11"),
      logo: <Neurophet height={27} width={107} style={{ marginTop: "-4px" }} />,
      link: "/NeuroMRI",
      delay: "",
    },
    {
      image: PromedCT,
      title: "Abdominal CT",
      visible: false,
      // desc: "근육/지방 면적 자동 분할 측정",
      desc: I18next.t("product-t12"),
      logo: <Promedius height={26} width={115} />,
      link: "/PromediusCT",
      delay: "",
    },
    {
      image: TalosPrediction,
      title: "Talos",
      visible: false,
      // desc: "뇌동맥류 예측 및 예방",
      desc: I18next.t("product-t13"),
      logo: <Talos height={16} width={78} />,
      delay: "",
    },
    {
      image: SelvasPrediction,
      title: "Selvas",
      visible: false,
      // desc: "주요 10대 질환 예측",
      desc: I18next.t("product-t14"),
      logo: <Selvas height={36} width={80} style={{ marginTop: "-16px" }} />,
      delay: "",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerPadding: "10px",
    initialSlide: 1, // 첫 컨텐츠 번호
    pauseOnHover: true, // hover시 정지
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="container-100 PlatformList-container-back">
      <div className="carousel-container">
        <p className="PalatformTitle" data-aos="fade-up">
          Medical AI Solution Platform
        </p>

        <Slider {...settings}>
          {solutions.map((solution) => (
            <SolutionImage
              image={solution.image}
              title={solution.title}
              visible={solution.visible}
              description={solution.desc}
              logo={solution.logo}
              link={solution.link}
              delay={solution.delay}
            />
          ))}
        </Slider>

        <p className="Palatform-text" data-aos="fade-up">
          {t("platform-t1")}
          {/* 마이링크의 다양한 AI 솔루션의 자세한 정보는 브로슈어를 통해 확인하실
          수 있습니다. */}
        </p>
        <Link
          data-aos="fade-up"
          className="LineButton"
          style={{ marginTop: "3.5vh" }}
          to={i18n.language === "ko" ? "/Brochure" : "/BrochureEN"}
          target="_blank"
        >
          <div>
            {t("platform-t2")}
            {/* 디지털 브로슈어 */}
          </div>
        </Link>
      </div>
    </div>
  );
}
