import React from "react";
import MailinkLogo from "./MailinkLogo";
import ProductBack from "../res/Product_Back.png";
import { ReactComponent as LunitMMGLogo } from "../res/Lunit_MMG.svg";
import LMMG from "../res/Product_LunitMMG.png";
import Calcification from "../res/Calcification.png";
import SoftTissue from "../res/Soft-tissue lesion.png";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import { useEffect } from "react";
import AOS from "aos";

export default function LunitMMGPage() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="container-100 Product-back"
      style={{ backgroundImage: `url(${ProductBack})` }}
    >
      <div className="Product-container">
        <div className="Product-title-container" data-aos="fade-up">
          <p className="Product-title">
            MAMMOGRAPHY
            <br />
            {t("lunit-mmg-t1")}
            {/* 영상 검출 및 진단 보조 솔루션 */}
          </p>
          <div className="Product-logo">
            <LunitMMGLogo width={196} height={20} />
            <p
              className={
                i18n.language === "ko" ? "Product-logo-text" : "hidden"
              }
            >
              {t("lunit-mmg-t2")}
              {/* 유방암영상검출·진단보조소프트웨어(3등급) */}
            </p>
          </div>
        </div>

        {/* 이미지 */}
        <div className="Product-image-container" data-aos="fade-up">
          <img src={LMMG} className="Product-image" />
        </div>
        <div
          className="Product-text-container"
          style={{ margin: "1vh 0 4vh 0" }}
        >
          <p className="Product-text" data-aos="fade-up">
            {t("lunit-mmg-t3")}
            {/* 이상 병변 */}
            <span className="Text-point-MMG">
              {t("lunit-mmg-t4")}
              {/* 검출 위치 */}
            </span>
            <br className="min-show768" />
            {t("lunit-mmg-t5")}
            {/* 및 이상 부위 값 표시 */}
          </p>
          <p
            data-aos="fade-up"
            style={{ marginTop: "4vh" }}
            className={
              i18n.language === "ko" ? "Product-text" : "Text-point-MMG"
            }
          >
            {" "}
            {t("lunit-mmg-t6")}
            {/* 양측 유방 */}
            <span
              className={
                i18n.language === "ko" ? "Text-point-MMG" : "Product-text"
              }
            >
              {t("lunit-mmg-t7")}
              {/* 치밀도 분석 */}
            </span>
          </p>
          <p
            className="Product-text"
            style={{ marginTop: "4vh" }}
            data-aos="fade-up"
          >
            {t("lunit-mmg-t8")}
            {/* 우수한 */}
            <span className="Text-point-MMG">
              {t("lunit-mmg-t9")}
              {/* 유방암 검출 */}
            </span>
            {t("lunit-mmg-t10")}
            {/* 보조 */}
          </p>
        </div>

        <div
          className="Product-desc-container"
          style={{ maxWidth: "800px" }}
          data-aos="fade-up"
        >
          <div className="Product-item-container">
            <div className="FindingMMG">
              {t("lunit-mmg-t11")}
              {/* 유방 석회화 */}
            </div>
            <img src={Calcification} className="Product-image" />
          </div>
          <div className="Product-item-container">
            <div className="FindingMMG">
              {t("lunit-mmg-t12")}
              {/* 유방 종괴 */}
            </div>
            <img src={SoftTissue} className="Product-image" />
          </div>
        </div>
        <p className="Text-c" style={{ marginTop: "20px" }}>
          {"< "}
          {t("lunit-mmg-t13")}
          {/* 유방암 의심 영역 및 비정상 수치 표시 */}
          {" >"}
        </p>
      </div>
    </div>
  );
}
